<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <div class="two-columns-wrapper">
        <div class="white-container materials">
          <h2>Общественные палаты регионов</h2>
          <div class="materials__tabs">
            <span v-for="(tab, index) in tabs" :class="{'active' : isActiveTab === index}" :key="`tab${index}`"
                  @click="setTabNews(tab.route)">{{ tab.name }}</span>
          </div>
          <h3>Перечень палат по округам</h3>
          <section v-for="(news, i) in resArray" :key="i" class="recommendation-block">
            <accordion :content="news" linkParametr="true"/>

          </section>
        </div>
        <similar-block-region title="Новости палат"
              :getNews="getNews"
              link="/news"
              v-if="getNews.length!==0" class="block_region"/>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import Accordion from '@/components/forms/Accordion'
import SimilarBlockRegion from '@/components/activitiesSurveys/SimilarBlockRegion'

export default {
  name: 'ChamberRegions',
  components: {
    BreadCrumb,
    Accordion,
    SimilarBlockRegion
  },
  data () {
    return {
      isActiveTab: 1,
      resArray: [],
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Общественные палаты регионов'
        }
      ],
      tabs: [
        {
          name: 'Новости',
          route: '/public_chamber_news'
        },
        {
          name: 'Палаты по округам',
          route: '/public_chamber_regions'
        },
        {
          name: 'Документы',
          route: '/public_chamber_materials'
        }
      ],
      recommendationNews: [
        {
          id: 1,
          title: 'Центральный федеральный округ',
          description: ''
        },
        {
          id: 2,
          title: 'Уральский федеральный округ',
          description: `<ul><li><a href="#/chamber-region">Общественная палата 1</a></li>
                            <li><a href="#/chamber-region">Общественная палата 2</a></li>
                            <li><a href="#/chamber-region">Общественная палата 3</a></li>
                        </ul>`
        },
        {
          id: 3,
          title: 'Северо-Западный федеральный округ',
          description: ''
        },
        {
          id: 4,
          title: 'Южный федеральный округ',
          description: ''
        },
        {
          id: 5,
          title: 'Северо-Кавказский федеральный округ',
          description: ''
        },
        {
          id: 6,
          title: 'Приволжский федеральный округ',
          description: ''
        },
        {
          id: 7,
          title: 'Сибирский федеральный округ',
          description: ''
        },
        {
          id: 8,
          title: 'Дальневосточный федеральный округ',
          description: ''
        }
      ]
    }
  },
  async mounted () {
    // await this.$store.dispatch('getNews', 'Палаты по округам')
    await this.$store.dispatch('getNewsCommissions', 'public_chamber_news')
    let res = await fetch(`${this.$store.getters.getUrlApi}api/regional-public-сhamber-two`)
    res = await res.json()
    this.resArray = await res.data
    document.title = 'Общественные палаты регионов'
  },
  computed: {
    getNews () {
      return this.$store.getters.getNewsCommissions ? this.$store.getters.getNewsCommissions.slice(0, 3) : []
    }
  },
  methods: {
    setTabNews (route) {
      console.log(route)
      this.$router.push({ path: `${route}` })
    }
  }
}
</script>
<style lang="scss" scoped>
.two-columns-wrapper{
  display: flex;
  justify-content: space-between;
}
  .white-container {
    width: 61.44rem;
    margin: 0;
    padding: 2.5rem 2.94rem;

    p {
      margin-bottom: 2.5rem;
      margin-top: .75rem;
      line-height: 157%;
      font-size: .875rem;
      color: rgba(23, 23, 23, 0.8);
    }

    .materials {
      display: flex;
      flex-direction: column;

      &__tabs {
        display: flex;
        padding-bottom: 1.375rem;
        border-bottom: .0625rem solid #D5D6D9;
        margin-bottom: 2rem;
        color: #003E78;

        span, .active {
          font-size: 0.8125rem;
          margin-right: 1.25rem;
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          opacity: 0.6;

          cursor: pointer;
        }

        .active {
          font-weight: 600;
          color: #246CB7;
          opacity: 1;
        }

        .active::before {
          content: '';
          height: 100%;
          width: 100%;
          bottom: -1.45rem;
          position: absolute;
          border-bottom: 0.125rem solid #246CB7;
        }

      }

    }
  }

  h2{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 2rem;
  }
  h3{
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.33;
    margin-bottom: 1.375rem;
    color: #1F3245;
  }

  .container__document {
    margin-top: 2rem;
  }

  @media screen and (max-width: 768px) {
    .two-columns-wrapper{
      flex-direction: column;
    }
    .white-container {
      width: calc(100% - 1.5rem);
      margin: 0 .75rem;
      padding: 1.86rem 1.25rem;
    }
    .block_region{
      display: none;
    }
  }

  @media screen and (max-width: 420px) {
    .white-container {
      width: 100%;
      margin: 0;
      padding: 1.88rem 1rem;
        .materials__tabs {
          overflow-x: scroll;
          flex-wrap: nowrap;
          -ms-overflow-style: none;
          overflow: -moz-scrollbars-none;
          padding-bottom: 1.38rem;
          span{
            flex: 0 0 auto;
          }
          .active::before {
            content: '';
            height: 100%;
            width: 100%;
            bottom: -1.38rem;
          }
        }
    }
  }
</style>
